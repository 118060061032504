@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


._2qp0Z {
    background-color: #07ae36!important;
    color: #ffffff!important;
  }
  
  .prose{
    max-width: 100vw!important;
  }